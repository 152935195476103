import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        year: new Date().getFullYear(),
        carpeta: '',
        empresa: '',
        multiEmisor: 0,
        denominacionComercial: '',
        emisor: '',
        logo: '',
        logo2: '',
        ticketMuestra: '',
        foto: '',
        facebook: '',
        twitter: '',
        youtube: '',
        instagram: '',
        telefono: '',
        email: '',
    },
    getters: {
        year: state => state.year,
        carpeta: state => state.carpeta,
        empresa: state => state.empresa,
        multiEmisor: state => state.multiEmisor,
        emisor: state => state.emisor,
        denominacionComercial: state => state.denominacionComercial,
        logo: state => state.logo,
        logo2: state => state.logo2,
        ticketMuestra: state => state.ticketMuestra,
        foto: state => state.foto,
        facebook: state => state.facebook,
        twitter: state  => state.twitter,
        youtube: state  => state.youtube,
        instagram: state  => state.instagram,
        telefono: state  => state.telefono,
        email: state  => state.email,
    },
    mutations: {
        setCarpeta: function(state, carpeta){
            state.carpeta = carpeta;
        },
        setEmpresa: function(state, empresa){
            state.empresa = empresa;
        },
        setMultiEmisor: function(state, multiEmisor){
            state.multiEmisor = parseInt(multiEmisor);
        },
        setEmisor: function(state, emisor){
            state.emisor = emisor;
        },
        setDenominacionComercial: function(state, denominacionComercial){
            state.denominacionComercial = denominacionComercial;
        },
        setLogo: function(state, logo){
            state.logo = logo;
        },
        setLogo2: function(state, logo2){
            state.logo2 = logo2;
        },
        setTicketMuestra: function(state, ticketMuestra){
            state.ticketMuestra = ticketMuestra;
        },
        setFoto: function(state, foto){
            state.foto = foto;
        },
        setFacebook: function(state, facebook){
            state.facebook = facebook;
        },
        setTwitter: function(state, twitter){
            state.twitter = twitter;
        },
        setYoutube: function(state, youtube){
            state.youtube = youtube;
        },
        setInstagram: function(state, instagram){
            state.instagram = instagram;
        },
        setTelefono: function(state, telefono){
            state.telefono = telefono;
        },
        setEmail: function(state, email){
            state.email = email;
        },
    },
    actions: {
    },
    modules: {
    }
})
