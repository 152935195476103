import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/facturar',
    name: 'facturar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "facturar" */ '../views/FacturarView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/consultar',
    name: 'consultar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "facturar" */ '../views/ConsultarView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/ayuda',
    name: 'ayuda',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "facturar" */ '../views/AyudaView.vue'),
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    window.console.log(router);
    if (to.matched.some(record => record.meta.requiresAuth)) {
        //if (!router.app.$token) {
        if (!store.getters.carpeta) {
            window.console.log('Requiere auth');
            //window.location.href = 'https://yomefacturo.mx';
            next('/');
            return;
        }
    }
    next();
});

export default router
