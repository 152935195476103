<template>
  <v-app id="app">
    <router-view/>
  </v-app>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.v-list-item__content {
    text-align: left;
}
</style>
<script>
export default {
  data: () => ({
  }),
  methods: {
    cargaInfo(){
        let este = this;
        let carpeta = window.location.href.split('/')[2].split('.')[0];
        if(carpeta == 'localhost:8080'){
            carpeta = 'mediamas';
        }
        this.$http.get(this.$protocolo + carpeta + this.$url + '/app').then(function(response){
            este.$store.commit('setCarpeta', response.data.carpeta);
            este.$store.commit('setEmpresa', response.data.nombre);
            este.$store.commit('setMultiEmisor', response.data.multiEmisor);
            este.$store.commit('setDenominacionComercial', response.data.denominacionComercial);
            este.$store.commit('setLogo', response.data.logo);
            este.$store.commit('setLogo2', response.data.logo2);
            este.$store.commit('setTicketMuestra', response.data.ticketMuestra);
            este.$store.commit('setFoto', response.data.foto);
            este.$store.commit('setFacebook', response.data.facebook);
            este.$store.commit('setInstagram', response.data.instagram);
            este.$store.commit('setYoutube', response.data.youtube);
            este.$store.commit('setTwitter', response.data.twitter);
            este.$store.commit('setTelefono', response.data.contactoTelefono);
            este.$store.commit('setEmail', response.data.contactoEmail);
            document.title = este.$store.getters.denominacionComercial + ' - Yo Me Facturo';
            console.log(response);
        }).catch(function(){
        });
    }
  },
  mounted(){
    this.cargaInfo();
  }
}
</script>
