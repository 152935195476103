<template>
  <div>
    <div
      class="home"
      :style="'background-image:url(' + $store.getters.foto + ')'"
    >
      <div class="formulario">
        <div class="form-top">
          <div class="form-top-left">
            <h2>Bienvenido</h2>
            <p class="font-weight-light">Seleccione la opción deseada:</p>
          </div>
          <div class="form-top-right">
            <img
              :alt="$store.getters.denominacionComercial + ' logo'"
              :src="$store.getters.logo"
              style="max-width: 200px"
            />
          </div>
        </div>
        <div class="form-bottom text-center">
          <router-link to="/facturar" class="btn" ref="btnFacturar"
            >FACTURAR TICKET</router-link
          >
          <router-link to="/consultar" class="btn" ref="btnConsultar"
            >DESCARGAR FACTURAS</router-link
          >
          <router-link to="/ayuda" class="btn" ref="btnAyuda"
            >AYUDA</router-link
          >
        </div>
      </div>
      <div class="yomefacturo">
        © {{ $store.getters.year }} |
        <a href="https://www.yomefacturo.mx" target="_blank">yomefacturo.mx</a>
      </div>
      <div class="social-login">
        <div class="social-login-buttons">
          <a
            v-if="$store.getters.facebook"
            class="btn-link-2"
            :href="$store.getters.facebook"
            target="_blank"
            ><v-icon class="icon">mdi-facebook</v-icon> Facebook</a
          >
          <a
            v-if="$store.getters.twitter"
            class="btn-link-2"
            :href="$store.getters.twitter"
            target="_blank"
            ><v-icon class="icon">mdi-twitter</v-icon> Twitter</a
          >
          <a
            v-if="$store.getters.youtube"
            class="btn-link-2"
            :href="$store.getters.youtube"
            target="_blank"
            ><v-icon class="icon">mdi-youtube</v-icon> Youtube</a
          >
          <a
            v-if="$store.getters.instagram"
            class="btn-link-2"
            :href="$store.getters.instagram"
            target="_blank"
            ><v-icon class="icon">mdi-instagram</v-icon> Instagram</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.home {
  color: #fff;
  height: 100%;
  width: 100%;
  background-repeat: none;
  background-size: cover;
  background-position: center center;
  position: absolute;
  z-index: 100;
}
.formulario img {
  max-width: 100%;
  margin: 25px 0 0 10px;
}
.formulario {
  color: #fff;
  max-width: 600px;
  margin: 180px auto 15px auto;
  padding-left: 0 !important;
  padding-right: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.74902) 10px 10px 38px 0px;
}
.yomefacturo {
  color: #fff;
  font-size: 12px;
  margin-top: 10px;
}
.yomefacturo a {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
.yomefacturo a:hover {
  text-decoration: underline;
}
.form-top {
  overflow: hidden;
  padding: 0 25px 0px 25px;
  background: #444;
  background: rgba(0, 0, 0, 0.2);
  -moz-border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  text-align: left;
}
.form-top-left {
  float: left;
  width: 50%;
  padding-top: 25px;
}
.form-top-right {
  float: left;
  width: 50%;
  padding-top: 5px;
  font-size: 66px;
  color: #fff;
  line-height: 100px;
  text-align: right;
  opacity: 1;
}
.form-bottom {
  padding: 25px 25px 30px 25px;
  background: #444;
  background: rgba(0, 0, 0, 0.15);
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  text-align: left;
}
.btn {
  background: rgba(24, 45, 116, 0.7);
  color: #fff;
  text-decoration: none;
  padding: 15px;
  width: 100%;
  display: block;
  text-align: center;
  margin: 15px auto;
  opacity: 1;
}
.btn:hover {
  opacity: 0.9;
  color: #fff;
}
.social-login {
  margin-top: 25px;
}
.btn-link-2 {
  display: inline-block;
  height: 50px;
  margin: 5px;
  padding: 15px 20px 0 20px;
  background: rgba(0, 0, 0, 0.15);
  border: 1px solid #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  color: #fff;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-decoration: none;
}
.btn-link-2:hover,
.btn-link-2:focus,
.btn-link-2:active,
.btn-link-2:active:focus {
  outline: 0;
  opacity: 0.6;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}
a,
a:hover,
a:focus {
  text-decoration: none;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}
a .icon {
  color: #fff;
}

@media screen and (max-width: 780px) {
  .formulario {
    width: 100%;
    margin: 100px 0px 15px 0px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-shadow: rgba(0, 0, 0, 0.74902) 10px 10px 38px 0px;
  }
  .formulario img {
    width: 90%;
    margin: 25px 0 0 0;
  }
  .form-top {
    display: flex;
    flex-direction: column-reverse;
  }
  .form-top-left {
    float: none;
    width: 100%;
    padding-top: 0;
    padding-bottom: 25px;
  }
  .form-top-right {
    float: none;
    width: 100%;
    text-align: center;
  }
}
</style>

<script>
export default {
  name: "HomeView",
  components: {},
  data: () => ({}),
  methods: {
    factura() {},
  },
  mounted() {
    if (localStorage.getItem("ticketid")) {
      let este = this;
      this.ticketId = localStorage.getItem("ticketid");
      setTimeout(function () {
        este.$router.push("/facturar");
      }, 300);
    }
  },
};
</script>
